import { userAtom } from "@iso/state/user";
import { useRecoilValue } from "recoil";

const SysContant = [
   {
      label: `Delivery System`,
      value: "DELI",
      links: "/deli/csa",
      role: "deli_system",
   },
   {
      label: `BYOD System`,
      value: "BYOD",
      links: "/byod",
      role: "byod_system",
   },
   {
      label: `Feedback System`,
      value: "Feedback",
      links: "/feedback",
      role: "feedback_system",
   },
   {
      label: `Master Managerment System`,
      value: "MMS",
      links: "/mms",
      role: "mms_system",
   },
   {
      label: `KMS System`,
      value: "KVS",
      links: "/kvs",
      role: "kms_system",
   },
];

const handleDirect = (e) => {
   window.location.href = SysContant.find((i) => i.value === e)?.links;
};

function ChooseSystem() {
   const user = useRecoilValue(userAtom);

   return (
      <div className="mx-8">
         <h1 className="text-center my-14 mb-12">Choose your workplace</h1>
         <div className="grid grid-cols-3 gap-6">
            {SysContant?.map(
               (item, index) =>
                  user?.roles?.includes(item?.role) && (
                     <div
                        className="flex text-center items-center justify-center py-24 border-2 font-bold hover:border-primary-500 rounded-2xl text-xl cursor-pointer"
                        key={index}
                        onClick={() => handleDirect(item?.value)}
                     >
                        {item.label}
                     </div>
                  )
            )}
         </div>
      </div>
   );
}

export default ChooseSystem;
