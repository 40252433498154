import Container from "component/Container/index";
import firebase from "firebase";
import { userAtom } from "@iso/state";
import { useRecoilValue } from "recoil";
import { Link, useHistory } from "react-router-dom";
import { Avatar, Menu, Dropdown } from "antd";
import Cookies from "universal-cookie";
import { Select } from "antd";
import styled from "styled-components";
import { removeStorage } from "@iso/helpers/index";

const { Option } = Select;

const SelecHeaderCustom = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   .ant-select-selector {
      font-size: 26px;
      color: white;
   }
   svg {
      fill: white;
   }
`;

const SysContant = [
   {
      label: `MMS Pizza 4P's Back-office`,
      value: "MMS",
      links: "/mms",
   },
   {
      label: `DELI Pizza 4P's Back-office`,
      value: "DELI",
      links: "/deli",
   },
   {
      label: `KVS Pizza 4P's Back-office`,
      value: "KVS",
      links: "/kvs",
   },
   {
      label: `BYOD Pizza 4P's Back-office`,
      value: "BYOD",
      links: "/byod",
   },
   {
      label: `Feedback Pizza 4P's Back-office`,
      value: "Feedback",
      links: "/feedback",
   },
];

function Header() {
   const cookies = new Cookies();
   const history = useHistory();
   const user = useRecoilValue(userAtom);

   const checkRoles = (roles) => {
      const userRoles = user?.roles || [];
      return roles.every((role) => userRoles.includes(role));
   };

   const menu = (
      <Menu style={{ minWidth: "16rem" }}>
         <Menu.Item>
            <Link to="/profile-setting">
               <span>Profile Setting</span>
            </Link>
         </Menu.Item>
         <Menu.SubMenu title="BO" className="text-gray-500">
            <Menu.Item
               style={{
                  minWidth: "15rem",
                  background: "#1a22411f",
               }}
               disabled
            >
               <a href="/mms" disabled>
                  <span>MMS</span>
               </a>
            </Menu.Item>
            <Menu.Item style={{ minWidth: "15rem" }}>
               <a href="/deli">
                  <span>Deli</span>
               </a>
            </Menu.Item>
            <Menu.Item style={{ minWidth: "15rem" }}>
               <a href="/kvs">
                  <span>KVS</span>
               </a>
            </Menu.Item>
            <Menu.Item style={{ minWidth: "15rem" }}>
               <a href="/byod">
                  <span>BYOD</span>
               </a>
            </Menu.Item>
            <Menu.Item style={{ minWidth: "15rem" }}>
               <a href="/feedback">
                  <span>Feedback</span>
               </a>
            </Menu.Item>
         </Menu.SubMenu>
         {/* {checkRoles(["product_item_list"]) && (
            <Menu.SubMenu title="Store" className="text-gray-500">
               {checkRoles(["product_item_list"]) && (
                  <Menu.Item style={{ minWidth: "15rem" }}>
                     <Link to="/store/products">
                        <span>Products</span>
                     </Link>
                  </Menu.Item>
               )}
            </Menu.SubMenu>
         )}
         {(checkRoles(["staff_list"]) || checkRoles(["group_role_list"])) && (
            <Menu.SubMenu title="Root" className="text-gray-500">
               {checkRoles(["staff_list"]) && (
                  <Menu.Item key="6">
                     <Link to="/root/staff-setting">Staff Settings</Link>
                  </Menu.Item>
               )}
               {checkRoles(["group_role_list"]) && (
                  <Menu.Item key="7">
                     <Link to="/root/group-role">Group role</Link>
                  </Menu.Item>
               )}
            </Menu.SubMenu>
         )} */}

         <Menu.Divider />
         <Menu.Item onClick={() => handleLogout()}>
            <span className="text-gray-500">Logout</span>
         </Menu.Item>
      </Menu>
   );

   const handleLogout = async () => {
      removeStorage("session");
      cookies.remove("token");
      cookies.remove("refreshToken");
      cookies.remove("email");
      cookies.remove("store_id");
      cookies.remove("photoURL");
      await firebase.auth().signOut();
      history.push("/");
   };

   const handleDirect = (e) => {
      window.location.href = SysContant.find((i) => i.value === e)?.links;
   };

   return (
      <div className="bg-primary-500 py-4 shadow-primary">
         <Container className="flex justify-between">
            {/* <Link to="/">
               <h1 className=" text-white-0 mb-0">
                  Pizza 4P's Mms Back-office
               </h1>
            </Link> */}
            <SelecHeaderCustom>
               <Select
                  defaultValue="MMS"
                  bordered={false}
                  onChange={handleDirect}
               >
                  {SysContant?.map((item, index) => (
                     <Option value={item?.value} key={index}>
                        {item?.label}
                     </Option>
                  ))}
               </Select>
            </SelecHeaderCustom>
            <Dropdown
               overlay={menu}
               placement="bottomRight"
               arrow
               trigger="click"
            >
               <Avatar
                  style={{ cursor: "pointer" }}
                  size={40}
                  src={user?.info?.avatar}
               />
            </Dropdown>
         </Container>
      </div>
   );
}

export default Header;
