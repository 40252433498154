import Header from "component/Header/index";
import { useState } from "react";
import { Layout, Menu } from "antd";
import { HomeOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import IcRoot from "@iso/assets/images/icon_settings_bo.png";
import IcStore from "@iso/assets/images/icon_store_bo.png";
import IcProduct from "@iso/assets/images/icon_product_bo.png";
import IcAuthorization from "@iso/assets/images/icon_authorization_bo.png";
import styled from "styled-components";
import useAuthor from "hooks/useAuthor";

const { Content, Sider } = Layout;

const SiderFood = styled.a`
   position: fixed;
   bottom: 48px;
   left: 0;
   width: 200px;
   padding: 12px;
   font-size: 14px;
   color: white;
   /* text-align: center; */
   display: flex;
   gap: 8px;
   /* justify-content: center; */
   align-items: center;
   &:hover {
      background: #242e52;
   }
`;

function MainLayout(props) {
   const { children } = props;
   const { checkRoles } = useAuthor();
   const history = useHistory();
   const [collapsed, setCollapsed] = useState(
      window.screen.width > 426 ? false : true
   );

   return (
      <div className="fade-in">
         <Layout>
            <Sider
               collapsedWidth={window.screen.width > 426 ? 80 : 0}
               collapsible
               collapsed={collapsed}
               className="wrapper-sidebar-menu"
               onCollapse={(value) => setCollapsed(value)}
            >
               <div className="logo " style={{ height: 65 }}>
                  <Link to="/">4P's</Link>
                  <button onClick={() => setCollapsed(true)}>
                     <MenuUnfoldOutlined />
                  </button>
               </div>
               <Menu
                  theme="dark"
                  mode="inline"
                  className="wrapper-custom-sidebar"
               >
                  {checkRoles(["product_item_list"]) && (
                     <Menu.SubMenu
                        title="Product"
                        icon={
                           <img
                              src={IcProduct}
                              style={{ maxWidth: 22, marginRight: 8 }}
                              alt="icon-root"
                           />
                        }
                     >
                        <Menu.Item
                           className={
                              history.location.pathname === "/product"
                                 ? "active-menu"
                                 : ""
                           }
                        >
                           <Link to="/product">Management</Link>
                        </Menu.Item>
                        <Menu.Item
                           className={
                              history.location.pathname ===
                              "/product/product-detail"
                                 ? "active-menu"
                                 : ""
                           }
                        >
                           <Link to="/product/product-detail">Detail</Link>
                        </Menu.Item>
                        {checkRoles(["add_on_list"]) && (
                           <Menu.Item
                              className={
                                 history.location.pathname === "/product/add-on"
                                    ? "active-menu"
                                    : ""
                              }
                           >
                              <Link to="/product/add-on">Add-On</Link>
                           </Menu.Item>
                        )}
                        {checkRoles(["attribute_list"]) && (
                           <Menu.Item
                              className={
                                 history.location.pathname ===
                                 "/product/attribute"
                                    ? "active-menu"
                                    : ""
                              }
                           >
                              <Link to="/product/attribute">Attribute</Link>
                           </Menu.Item>
                        )}
                     </Menu.SubMenu>
                  )}
                  {checkRoles(["store_list"]) && (
                     <Menu.Item
                        className={
                           history.location.pathname === "/store"
                              ? "active-menu"
                              : ""
                        }
                        icon={
                           <img
                              src={IcStore}
                              style={{ maxWidth: 26, marginRight: 8 }}
                              alt="icon-root"
                           />
                        }
                     >
                        <Link to="/store">Store</Link>
                     </Menu.Item>
                  )}
                  {checkRoles(["product_item_list"]) && (
                     <Menu.SubMenu
                        title="General"
                        icon={
                           <img
                              src={IcRoot}
                              style={{ maxWidth: 25, marginRight: 8 }}
                              alt="icon-root"
                           />
                        }
                     >
                        {(checkRoles(["lang_list"]) ||
                           checkRoles(["lang_add"]) ||
                           checkRoles(["lang_edit"])) && (
                           <Menu.Item
                              className={
                                 history.location.pathname === "/lang"
                                    ? "active-menu"
                                    : ""
                              }
                           >
                              <Link to="/lang">Language</Link>
                           </Menu.Item>
                        )}
                        {(checkRoles(["menu_item_list"]) ||
                           checkRoles(["menu_item_add"]) ||
                           checkRoles(["menu_item_edit"])) && (
                           <Menu.Item
                              className={
                                 history.location.pathname === "/menu-item"
                                    ? "active-menu"
                                    : ""
                              }
                           >
                              <Link to="/menu-item">Menu Item</Link>
                           </Menu.Item>
                        )}
                        {/* )} */}
                     </Menu.SubMenu>
                  )}
                  {(checkRoles(["staff_list"]) ||
                     checkRoles(["group_role_list"])) && (
                     <Menu.SubMenu
                        title="Authen"
                        icon={
                           <img
                              src={IcAuthorization}
                              style={{ maxWidth: 21, marginRight: 8 }}
                              alt="icon-root"
                           />
                        }
                     >
                        {checkRoles(["staff_list"]) && (
                           <Menu.Item
                              key="6"
                              className={
                                 history.location.pathname ===
                                 "/root/staff-setting"
                                    ? "active-menu"
                                    : ""
                              }
                           >
                              <Link to="/root/staff-setting">
                                 Staff Settings
                              </Link>
                           </Menu.Item>
                        )}
                        {checkRoles(["group_role_list"]) && (
                           <Menu.Item
                              key="7"
                              className={
                                 history.location.pathname ===
                                 "/root/group-role"
                                    ? "active-menu"
                                    : ""
                              }
                           >
                              <Link to="/root/group-role">Group role</Link>
                           </Menu.Item>
                        )}
                     </Menu.SubMenu>
                  )}
                  {checkRoles(["schedule_surcharge_list"]) && (
                     <Menu.Item
                        className={
                           history.location.pathname === "/schedule-surcharge"
                              ? "active-menu"
                              : ""
                        }
                        icon={
                           <img
                              src={IcStore}
                              style={{ maxWidth: 26, marginRight: 0 }}
                              alt="icon-root"
                           />
                        }
                     >
                        <Link to="/schedule-surcharge">Surcharge Schedule</Link>
                     </Menu.Item>
                  )}
               </Menu>
               <SiderFood
                  href="/mms/choose-system"
                  style={{ width: collapsed ? 80 : 200 }}
               >
                  <HomeOutlined className={`${collapsed ? "mx-auto" : ""}`} />
                  {!collapsed && <span>Back to Homepage</span>}
               </SiderFood>
            </Sider>
            <Layout className="site-layout">
               <Header />
               <Content>{children}</Content>
            </Layout>
         </Layout>
      </div>
   );
}

export default MainLayout;
